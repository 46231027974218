.save-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}
  
.save-modal-content {
    background: rgba(0, 0, 0, 1);
    border-radius: 12px;
    border: 0.5px solid white;
    color: white;
    padding: 20px;
    width: 350px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
    text-align: left;
    box-sizing: border-box;
}

.save-modal-heading {
    margin-bottom: 15px;
    font-size: 1.5rem;
    text-align: left;
}

.save-modal-input {
    width: 100%;
    padding: 10px;
    margin: 10px 0 20px 0;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    background: #222;
    color: white;
    box-sizing: border-box;
}

.save-modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.save-button,
.save-cancel-button {
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
}

.save-button {
    background-color: #009E73;
    color: white;
}

.save-cancel-button {
    background-color: #5a5a5a;
    color: white;
}  

.save-modal-error {
    color: #FF6961;
}