/* App.css or login.css */

.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  text-align: center;
  background: linear-gradient(135deg, #555555, #000000);  /* Gradient background */
}

.login h1 {
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 20px;
  font-weight: bold;
}

.login button {
  padding: 12px 30px;
  font-size: 1.2rem;
  background-color: #fff;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login button:hover {
  background-color: #e6e6e6;
}

.login button:focus {
  outline: none;
}

.loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
  }
  
  .loading-screen h2 {
    font-size: 2rem;
    color: #333;
  }
  