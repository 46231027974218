.toolbar {
    display: flex;
    align-items: center;
    gap: 13px;
  }
  
  .sign-in {
    display: flex;
    align-items: center;
    font-size: 12px;
    gap: 2px;
    color: white;
    white-space: nowrap;
    min-width: 50px;
  }

  @media (max-width: 1000px) {
    .toolbar {
      display: none;
    }
  }

  /* Authentication section (Welcome message + Sign out button) */
.auth-section {
  display: flex;
  align-items: center;
  margin-right: auto;
}

/* Welcome message styling */
.auth-section span {
  margin-right: 10px;
  font-size: 12px;
  font-family: 'FoundryMonoline';
  color: #ffffff;
}

/* Sign Out button styling */
.auth-section button {
  background-color: hsl(0, 0%, 0%);
  color: #ffffff; 
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'FoundryMonoline';
  font-size: 12px;
}

.profile-button {
  background-color: black; 
  border-radius: 50%;    
  padding: 6px;         
  object-fit: cover;      
}