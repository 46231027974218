@font-face {
  font-family: 'FoundryMonoline';
  src: url('./fonts/FoundryMonoline-Bold.woff2') format('woff2'),
       url('./fonts/FoundryMonoline-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'FoundryMonoline';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}