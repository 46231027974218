.ai-tab {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    transition: height 0.1s ease;
    overflow: hidden;
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-sizing: border-box; /* Ensure borders are included in width */
  }
  
  .resize-handle {
    height: 5px;
    cursor: ns-resize;
    background-color: rgba(255, 255, 255, 0.2);
    transition: background-color 0.2s ease;
  }
  
  .resize-handle:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
  
  .tab-content {
    padding: 5px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: transparent;
    border: none;
    font-size: 25px;
    color: rgba(255, 255, 255, 0.588);
    cursor: pointer;
  }
  
  .ai-controls {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Distribute space between mic/text and send icon */
    padding-top: 20px;
    padding-right: 10px;
    padding-left: 10px;
  }
  
  .icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  
  .mic-icon {
    margin-right: 8px; /* Space between mic icon and text */
  }
  
  .send-icon {
    margin-left: auto; /* Push send icon to the right end */
    padding-right: 8px; /* Additional padding on the right for spacing */
  }
  
  .text {
    color: #ffffffad;
    font-size: 14px;
  }  