.tabs {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 100%;
}

.tab-container {
  position: relative;
  display: flex;
  align-items: center;
}

.tab-button {
  height: 100%;
  font-size: 10px;
  padding: 6px 14px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: inherit;
}

.tab-button:hover {
  background-color: #555;
}

.tab-button.active {
  background-color: #808080;
}

.plus-button {
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 6px 12px;
  transition: background-color 0.3s ease;
}

.plus-button:hover {
  background-color: #555;
}

.delete-button {
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-95%);
  background: transparent;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  visibility: hidden;
  transition: color 0.3s ease;
}

.tab-container:hover .delete-button {
  visibility: visible;
}

.rename-input {
  font-size: 10px;
  padding: 6px 12px;
  background-color: #111;
  color: #fff;
  border: 1px solid #444;
  border-radius: 3px;
  width: 100%;
  box-sizing: border-box;
  font-family: inherit;
  outline: none;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.rename-input:focus {
  border-color: #555;
  background-color: #222;
}