.visualization {
    height: calc(100% - 27px);
    width: 100%;
    box-sizing: border-box; /* Ensure padding is included in the element's total width and height */
    display: flex;
    flex-direction: column;
    overflow: hidden; /* Ensure no scrollbars appear */
}

.visualizations-svg {
    width: 100%;
    height: 100%;
    display: block; /* Ensure there's no extra space around the SVG */
}
