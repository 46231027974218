.dropdown {
  position: absolute;
  background-color: #fff;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: none;
}

.dropdown-item {
  padding: 8px 12px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}
