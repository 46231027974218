/* ======================================
   MapWithLayers.css
   ======================================
   Recommended:
   - Use CSS variables to easily update colors and fonts.
   - Ensure that interactive elements (e.g. popup close button) have high contrast.
   - Adjust paddings and margins to keep your UI clear.
====================================== */

/* Define theme variables for easy customization */
:root {
  --primary-bg-color: #000;
  --primary-text-color: #fff;
  --legend-bg-color: rgba(0, 0, 0, 0.7);
  --legend-border-color: rgba(255, 255, 255, 0.3);
  --popup-bg-color: rgba(0, 0, 0, 0.8);
  --popup-text-color: #fff;
  --popup-close-bg: rgba(0, 0, 0, 0.6);
  --popup-close-bg-hover: rgba(0, 0, 0, 0.8);
}

/* Map Container */
.map-container {
  position: relative;
  overflow: hidden;
  height: 100vh; /* Adjust as needed */
  width: 100%;
}

/* Toggle Legend Button */
.toggle-legend {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--primary-bg-color);
  color: var(--primary-text-color);
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
  font-size: 14px;
  border-radius: 5px;
  opacity: 0.8;
  transition: opacity 0.2s ease;
}

.toggle-legend:hover {
  opacity: 1;
}

/* Legend Container */
.legend {
  position: absolute;
  top: 50px;
  right: 10px;
  background-color: var(--legend-bg-color);
  color: var(--primary-text-color);
  padding: 10px;
  border-radius: 4px;
  z-index: 10;
  max-height: 400px; /* Optional: Set a maximum height */
  overflow: hidden; /* Hide overflowing content */
}

/* Legend Header */
.legend h3 {
  margin-top: 0;
  font-size: 14px;
  text-align: center;
  font-family: 'FoundryMonoline', sans-serif;
}

/* Legend List */
.legend ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

/* Legend List Items */
.legend li {
  margin: 5px 0;
}

/* Layer Name Styling */
.legend .layer-name {
  font-weight: bold;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.legend .layer-name input {
  margin-right: 8px;
}

/* Layer Categories Container */
.legend .layer-categories {
  margin-left: 20px; /* Indent categories under the layer */
  padding-left: 10px;
  border-left: 2px solid var(--legend-border-color);
  
  /* Make categories scrollable if they exceed 5 */
  max-height: 120px; /* Adjust based on item height */
  overflow-y: auto;
}

/* Layer Category Styling */
.legend .layer-category {
  display: flex;
  align-items: center;
  margin: 3px 0;
}

/* Scrollable Legend Content */
.legend-content {
  max-height: 300px; /* Adjust based on design needs */
  overflow-y: auto;
  padding-right: 10px; /* Prevent content from hiding behind scrollbar */
}

/* Optional: Customize Scrollbar for WebKit Browsers */
.legend-content::-webkit-scrollbar {
  width: 8px;
}

.legend-content::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}

.legend-content::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
}

.legend-content::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.7);
}

/* Optional: Customize Scrollbar for .layer-categories */
.legend .layer-categories::-webkit-scrollbar {
  width: 6px;
}

.legend .layer-categories::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
}

.legend .layer-categories::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 3px;
}

.legend .layer-categories::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.7);
}

/* ======================================
   Enhanced Popup (Tooltip) Styling
====================================== */
.mapboxgl-popup {
  max-width: 300px;
}

.mapboxgl-popup-content {
  background: var(--popup-bg-color);
  color: var(--popup-text-color);
  border-radius: 5px;
  /* Extra right padding for the close button */
  padding: 10px 40px 10px 10px;
  font-size: 12px;
  position: relative;
}

/* Popup Close Button */
.mapboxgl-popup-close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 0;
  margin: 0;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  color: var(--popup-text-color);
  background: var(--popup-close-bg);
  border: none;
  border-radius: 50%;
  font-size: 16px;
  cursor: pointer;
  opacity: 1;
  z-index: 1;
}

.mapboxgl-popup-close-button:hover {
  background: var(--popup-close-bg-hover);
}

/* ======================================
   Additional Recommendations:
   - Consider using media queries for responsiveness.
   - Use CSS variables (as shown) to simplify theme changes.
   - Test on multiple browsers and devices.
====================================== */
