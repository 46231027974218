.panel {
    padding: 0px;
    background-color: black;
    border-radius: 10px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    overflow: hidden; /* Ensure content does not overflow the panel */
    display: flex;
    flex-direction: column; /* Ensures header and visualization are stacked vertically */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }
  
  .panel-header {
    width: 100%;
    height: 27px;
    display: flex;
    justify-content: space-between;
    align-items: center; /* Align items to the center */
  }
  
  .panel-icons {
    height: 100%;
    display: flex;
    gap: 3px;
    flex-shrink: 0; /* Prevent icons from shrinking */
  }
  
  .panel-icon-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }
  
  .panel-icon-button:focus {
    outline: none;
  }
  
  .panel-icon {
    width: 24px;
    height: 24px;
  }
  
  .panel-name {
    padding-left: 10px;
    font-size: 14px;
    font-weight: bold;
    color: white;
    white-space: nowrap; /* Prevent the name from wrapping to a new line */
    overflow: hidden; /* Ensure long names do not overflow */
    text-overflow: ellipsis; /* Show ellipsis for long names */
    flex-grow: 1; /* Allow name to grow and take available space */
    padding-right: 10px; /* Add some padding to the right */
    cursor: pointer; /* Show pointer cursor to indicate it is clickable */
  }
  
  .panel-name-input {
    font-size: 14px;
    font-weight: bold;
    color: white;
    background-color: transparent; /* Make the background transparent */
    border: none; /* Remove border */
    width: 100%; /* Make input width 100% */
    outline: none; /* Remove default outline */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margin */
    flex-grow: 1; /* Allow input to grow and take available space */
  }
  