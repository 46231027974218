:root{
  background: radial-gradient(circle at 20% 20%, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.5)),
    radial-gradient(circle at 80% 80%, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.8)),
    radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.4), rgba(0, 0, 0, 0.6)),
    radial-gradient(circle at 30% 70%, rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.5)),
    radial-gradient(circle at 70% 30%, rgba(255, 255, 255, 0.6), rgba(0, 0, 0, 0.4));
  background-color: #262728;
  font-size: 12px;
  --menu-height: 50px;
  --tab-height: 24px;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: auto;
}

.menu{
  height: var(--menu-height);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.workspace{
  height: calc(100vh - var(--menu-height));
}

.tabs-parent{
  height: var(--tab-height);
  margin-left: 7px;
  position: sticky;
  top: var(--menu-height);
  z-index: 500;
}

.viewport-parent{
  height: calc(100vh - var(--menu-height) - var(--tab-height));
  min-height: 500px;
  overflow: auto;
}

.login-background {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.login-background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./assets/darktheme/sign-in-bg.svg');
  background-size: cover;
  background-position: center;
  filter: blur(4px); /* Apply blur only to the background image */
  z-index: 0; /* Place it behind the login content */
}

.login-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
  z-index: 1; /* Place it on top of the blurred background */
}

.login-content h1 {
  font-size: 3.5em;
  margin-bottom: 10px;
}

.login-content p {
  font-size: 1.2em;
  font-weight: normal;
  margin-bottom: 20px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.5;
  opacity: 0.7; /* Slight transparency to differentiate from the heading */
}

.login-content button {
  padding: 10px 20px;
  font-size: 1.2em;
  color: #000000;
  background-color: #fdfdfd;
  border: none;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-content button:hover {
  background-color: #b5b5b5;
}
