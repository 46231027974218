.menubar {
  display: inline-flex;
  background-color: black; 
  border-radius: 10px;; 
  padding: 8px;
}

.menu-item {
  display: flex; 
  align-items: center; 
  padding-right: 5px; 
  color: white; 
  font-size: 10px;
  white-space: nowrap;
  margin-right: 5px;
  margin-left: 5px;
  position: relative;
  cursor: pointer;
}

.menu-icon {
  vertical-align: middle; 
  margin-right: 5px; 
  width: 25px;
  height: 25px;
}

.menu-label {
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
}

.menu-item:hover {
  background-color: #444;
  color: white;
  border-radius: 5px;
}

.dropdown {
  display: none; /* Hidden by default, will be shown on hover */
  position: absolute;
  left: 0; /* Align with the start of the menu item */
  top: 100%; /* Position below the menu item */
  background-color: #fff;
  color: #333;
  border: 1px solid #ddd;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 2000; /* Ensure dropdown appears above other content */
  min-width: 100%; /* Match width of the parent menu item */
}

.menu-item:hover .dropdown {
  display: block; /* Show dropdown when parent menu item is hovered */
}

.dropdown-item {
  padding: 8px 12px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.delete-icon {
  margin-left: 10px;
  cursor: pointer;
  color: gray;
  transition: color 0.3s;
}
.delete-icon:hover {
  color: red;
}