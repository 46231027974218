.visualization-modal-paper {
    background-color: black !important;
    color: white !important;
    width: 100% !important;
    height: 50% !important;
    backdrop-filter: blur(30px) !important;
  }
  
  .panel-icon-button {
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
  }