.navigation-bar {
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.collapsed-menu-icon{
  display: none;
}

@media (max-width: 1000px) {
  .collapsed-menu-icon {
    display: block;
    padding-right: 10px;
  }
}